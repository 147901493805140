.top-banner-section {
  position: relative;
  .reward-title {
    position: absolute;
    top: 40%;
    left: 18%;
    font-size: 120px;
    padding-top: 40px;
    font-family: "BrbasNeueBoldItalic";
    line-height: 0.8;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: -4px;
    word-spacing: 4px;
  }
}

.description {
  font-family: "Outfit", sans-serif;
  color: #fff;
  padding-top: 20px;
  font-size: 20px;
}

.second-section {
  // background-color: #1a1a1a;
  color: #fff;
  font-family: "Outfit", sans-serif;
  padding: 5% 10%;
  text-align: center;
}

.rewards-card-section {
  background-color: #1a1a1a;
  color: #fff;
  font-family: "Outfit", sans-serif;
  font-size: 18px;
  .rewards-card-row {
    padding: 80px 0;
  }
}

.reward-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.reward-card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-header .btn-close {
  background-color: #fff !important;
}

@media screen and (max-width: 768px) {
  .height-80vh {
    height: auto;
  }
 
}

@media screen and (max-width: 540px) {
  .top-banner-section {
    .reward-title {
      font-size: 40px !important;
      left: 12%;
    }
  }

}
