.mainDiv{
    height: 100%;
    width: 100%;
    padding: 20px;

    .imgBox{
        height: 100%;
        width: 500px;
    }
    .img{
        width: 70%;
        border-radius: 22px;
    }
    .erroInfo h1{
        color: white;
        margin-top: 2rem;
        text-align: center;
    }
    .erroInfo h2{
        color: #555555;
    }
    .erroInfo p{
        color: yellow;
        text-decoration: underline;
    }
}