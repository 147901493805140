.form-row {
  padding: 20px;
  .form-error {
    color: red;
  }
}

.inputField {
  width: 100%;
  font-size: 16px;
  border-radius: 18px;
  border: none;
  padding: 10px;
}
