.banner-container {
    position: relative;
    text-align: left;
    /* Align text to the left */
}

.banner-image {
    width: 100%;
    height: auto;
    height: 600px;
    object-fit: cover;
    filter: brightness(26%);
}

.banner-text {
    position: absolute;
    top: 50%;
    left: 10%;
    /* Adjust the left position as needed */
    transform: translate(0, -50%);
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
}
.top-heading{
    color:white;
    font-size: 45px; 
}
.t-description{
    margin: 18px 0;
    color: #cfcdcd;
    font-size: 18px;
}